.modal {
    background: #0a0a0a;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
    height: 100dvh;
    overflow-y: auto;

    @media (width >= 513px) {
        width: 512px;
    }
}
