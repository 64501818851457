.cpTipPlaceholder {
    width: 100%;
    aspect-ratio: 393 / 60;
}

.cpTipBanner {
    position: fixed;
    top: 56px;
    left: 0;
    width: 100%;
    z-index: 99;

    @media (width >= 513px) {
        width: 512px;
        left: 50%;
        transform: translateX(-50%) !important;
    }

    .closeBtn {
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    img {
        width: 100%;
        height: initial !important;
    }
}
